<template>
	<div class="page">
		<div class="page-title">{{ routerTitle }}</div>
		<el-row :gutter="20">
			<el-col :span="4">
				<el-tree :data="treeData" :highlight-current="true" node-key="value" @node-click="nodeClick" :expand-on-click-node="false"></el-tree>
			</el-col>
			<el-col :span="20">
			<div class="search">
					<div class="select-wrap">
						<el-form inline :model="params" ref="filtersForm">
							<el-form-item label="料号" prop="code">
								<el-input placeholder="请输入" v-model="params.code" clearable></el-input>
							</el-form-item>
							<el-form-item label="名称" prop="name">
								<el-input placeholder="请输入" v-model="params.name" clearable></el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="search-btn" style="white-space: nowrap">
					  <el-button type="primary" icon="el-icon-search" @click="searchData"></el-button>
					  <el-button type="warning" @click="finishHandle">
					  	处理完成
					  </el-button>
					</div>
				</div>
				<div class="page-content">
				  <div class="table-wrap">
				  	<el-table
				  		:data="tableData"
				  		style="margin-bottom: 40px; width: 100%"
				  		row-key="code"
				  		border
				  		v-loading="loading"
				  		:row-class-name="tableRowClassName"
				  	>
				  		<el-table-column width="50" />
				  		<el-table-column
				  			align="center"
				  			label="序号"
				  			type="index"
				  			width="60"
				  		/>
				  		<el-table-column
				  			show-overflow-tooltip
				  			prop="code"
				  			label="料号"
				  			width="150"
				  		/>
				  		<el-table-column show-overflow-tooltip prop="name" label="名称" />
				  		<el-table-column show-overflow-tooltip prop="spec" label="规格" />
				  		<el-table-column
				  			header-align="center"
				  			align="center"
				  			show-overflow-tooltip
				  			label="状态"
				  			width="80"
				  		>
				  			<template slot-scope="{ row }">
				  				{{ statusFilter(row.status) }}
				  			</template>
				  		</el-table-column>
				  		<el-table-column
				  			header-align="center"
				  			align="center"
				  			label="是否可见"
				  			width="180"
				  		>
				  			<template slot-scope="{ row, $index }">
				  				<el-switch
				  					v-model="row.showOrNot"
				  					:active-value="1"
				  					:inactive-value="0"
				  					active-text="可见"
				  					inactive-text="隐藏"
				  					@change="v => enableChange(v, row)"
				  				></el-switch>
				  			</template>
				  		</el-table-column>
				  	</el-table>
				  </div>
				  <Pagination
				    :total="total"
				    :page-size.sync="params.pageSize"
				    :page.sync="params.pageNum"
				    @change="getData"
				  />
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import watchParamsGetData from '../../mixins/watchParamsGetData'
export default {
	data() {
		return {
			treeData: [],
			params: {
				category: '',
				pageNum: 1
			}
		}
	},
	computed: {
	  routerTitle() {
	    return this.$route.meta.title
	  }
	},
	mixins: [watchParamsGetData],
	created() {
		this.listClass()
	},
	methods: {
		async listClass() {
			try {
				this.treeData = await this.$api.bom.listInventoryClass()
			} catch (err) {
				console.log(err)
			}
		},
		statusFilter(value) {
			const obj = { 1: '待处理', 2: '正常', 3: '已禁用' }
			return obj[value] || value || '-'
		},
		async nodeClick(item) {
			this.params.category = item.value
			this.params.pageNum = 1
			this.getData()
		},
		async searchData() {
			this.params.pageNum = 1
			this.getData()
		},
		async getData() {
			try {
				this.loading = true
				const { list, total } = await this.$api.bom.listInventory(this.params)
				this.tableData = list
				this.total = total
			} catch (err) {
				console.log(err)
			} finally {
				this.loading = false
			}
		},
		async enableChange(v, row) {
			try {
				this.loading = true
				const res = await this.$api.bom.setInventoryShow(row.code)
				this.$message.success('是否可见状态修改成功')
			} catch (err) {
				console.log(err)
			} finally {
				this.loading = false
			}
		},
		//处理完成事件
		async finishHandle() {
			try {
				this.loading = true
				const res = await this.$api.bom.setInventoryFinish()
				this.$message.success('处理完成提交成功')
				this.getData()
			} catch (err) {
				console.log(err)
			} finally {
				this.loading = false
			}
		},
		tableRowClassName({ row, rowIndex }) {
			// 1-未处理；2-已处理；3-已停用
			if (row.status === 1) {
				return 'danger-row'
			}
			if (row.status === 3) {
				return 'grey-row'
			}
			if (row.show === 1 && row.status === 2) {
				return 'success-row'
			}
			return ''
		}
	},
	beforeDestroy() {
		this.$store.commit('setState', { keyName: 'bomDetails', val: {} })
	}
}
</script>

<style lang="less" scoped>
.page {
  padding: 15px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  &.pageFix {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100vh - 30px);
    box-sizing: border-box;
    .page-content {
      flex: 1;
      display: flex;
      height: 0;
      flex-direction: column;
      overflow: auto;
      /deep/ .table-wrap {
        position: relative;
        flex: 1;
        .el-table {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
.page-title {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f6f6f6;
  font-size: 14px;
}
.page-content {
  padding-top: 15px;
}
.search {
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
  // align-items: center;
  // justify-content: space-between;
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  .search-btn {
    margin-left: 20px;
  }
}
.bottom {
  position: fixed;
  bottom: 25px;
  right: 50px;
  z-index: 100;
}
</style>